<template>
    <section>
        <the-navbar :public="true" />
        <div style="padding-top: 66px" class="columns">
            <div class="column is-half-desktop is-offset-one-quarter-desktop is-relative section is-medium">
                <div class="card" v-if="!temporal_token">
                    <header class="card-header">
                        <p class="card-header-title">
                            Recuperar el acceso a tu cuenta
                        </p>
                    </header>
                    <div class="card-content">
                        <form @submit.prevent="handleSendEmail" v-if="!sended">
                            <b-field label="Email" label-position="on-border">
                                <b-input
                                    v-model="form.email"
                                    name="email"
                                    required
                                ></b-input>
                            </b-field>
                            <br />
                            <b-button
                                :loading="loading"
                                type="is-primary"
                                native-type="submit"
                                expanded
                            >
                                Enviar
                            </b-button>
                        </form>
                        <div v-else>
                            <div v-if="!inputConfirmationCodeEnable" class="content has-text-centered">
                                <b-icon
                                    icon="check-circle"
                                    size="is-large"
                                    type="is-success"
                                />
                                <h5 class="pt-2">
                                    Se ha enviado un correo a tu cuenta de
                                    correo electrónico
                                </h5>
                                <b-button
                                    type="is-primary is-light"
                                    @click="inputConfirmationCodeEnable = true"
                                >
                                    Ingresar código de verificación
                                </b-button>
                            </div>
                            <div v-else>
                                <form @submit.prevent="handleSendResetCode">
                                    <b-field label="Ingresar código envíado a su correo" label-position="on-border">
                                        <b-input
                                            v-model="formResetCode.codigo"
                                            name="codigo"
                                            required
                                        ></b-input>
                                    </b-field>
                                    <br />
                                    <b-button
                                        :loading="loading"
                                        type="is-primary"
                                        native-type="submit"
                                        expanded
                                    >
                                        Enviar
                                    </b-button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-else class="card">
                    <header class="card-header">
                        <p class="card-header-title">
                            Restablecer contraseña
                        </p>
                    </header>
                    <div class="card-content">
                        <form @submit.prevent="handleResetPassword" v-if="!resetedPassword">
                            <b-field label="Nueva contraseña" label-position="on-border">
                                <b-input
                                    v-model="formResetPassword.password"
                                    name="password"
                                    required
                                    password-reveal
                                    type="password"
                                ></b-input>
                            </b-field>
                            <b-field label="Confirmar contraseña" label-position="on-border">
                                <b-input
                                    v-model="formResetPassword.password_confirmation"
                                    name="password_confirmation"
                                    required
                                    password-reveal
                                    type="password"
                                ></b-input>
                            </b-field>
                            <br />
                            <b-button
                                :loading="loading"
                                type="is-primary"
                                native-type="submit"
                                expanded
                            >
                                Enviar
                            </b-button>
                        </form>
                        <div v-else class="content has-text-centered">
                            <b-icon
                                icon="check-circle"
                                size="is-large"
                                type="is-success"
                            />
                            <h5 class="pt-2">
                                Se ha restablecido la contraseña correctamente
                            </h5>
                            <b-button
                                type="is-primary is-light"
                                @click="redirectToLogin"
                            >
                                Ir a inicio de sesión
                            </b-button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import TheNavbar from '@/components/shared/Layout/TheNavbar.vue';

import Message from '@/utils/Message';

export default {
    name: 'ResetPassword',
    components: {
        TheNavbar,
    },
    data() {
        return {
            form: {
                email: '',
            },
            formResetCode: {
                codigo: '',
            },
            formResetPassword: {
                password: '',
                password_confirmation: '',
            },
            temporal_token: '',
            sended: false,
            inputConfirmationCodeEnable: false,
            loading: false,
            resetedPassword: false,
        };
    },
    mounted() {

    },
    methods: {
        redirectToLogin() {
            /* if (navigator.userAgent.toLowerCase().indexOf("iphone") > -1) {
                window.location.href = 'https://itunes.apple.com/my/app/flipbizz/idexampleapp';
            } */

            if (navigator.userAgent.toLowerCase().indexOf("android") > -1) {
                window.location.href = 'https://play.google.com/store/apps/details?id=com.ditverfrut.appboletas';
            }

            //Desktop Browser
            if (!navigator.userAgent.match(/(iPhone|iPod|iPad|Android|BlackBerry|IEMobile)/)) {
                window.location.href = '/';
            }

            // window.location.replace('https://play.google.com/store/apps/details?id=com.ditverfrut.appboletas')
        },
        async handleSendEmail() {
            try {
                this.loading = true;
                const { email } = this.form;

                await this.$store.dispatch('auth/forgotPassword', { email });

                this.sended = true;
                this.form.email = '';
            } catch (error) {
                Message.showErrorMessage(this, error.message);
            } finally {
                this.loading = false;
            }
        },
        async handleSendResetCode() {
            this.loading = true;
            try {
                const { codigo } = this.formResetCode;

                const { token } = await this.$store.dispatch('auth/sendResetCode', { codigo });
                this.formResetCode.codigo = '';
                this.temporal_token = token;

            } catch (error) {
                Message.showErrorMessage(this, error.message);
            } finally {
                this.loading = false;
            }
        },
        async handleResetPassword() {
            this.loading = true;
            try {
                const { password, password_confirmation } = this.formResetPassword;

                const { message } = await this.$store.dispatch('auth/resetPassword', {
                    password,
                    password_confirmation,
                    temporalToken: this.temporal_token,
                });

                Message.showSuccessMessage(this, message);

                this.resetedPassword = true;
            } catch (error) {
                Message.showErrorMessage(this, error.message, error?.errors);
            } finally {
                this.loading = false;
            }
        }
    },
};
</script>

<style>
</style>
